import { styled } from 'twin.macro';
import React from 'react';

import { Subtitle, Heading } from '../atoms';

const Container = styled.div`
  background-image: url('/images/apps-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center bottom;
  background-size: cover;
  .bg-blur {
    background: rgba(0, 0, 0, 0.6);
  }
`;

const AppFeatures = ({ data }) => (
  <Container>
    <div className="bg-blur">
      <div tw="container px-4 mx-auto py-16">
        <div tw="text-center mb-8">
          <Heading isLarge hasTextWhite>
            ProField
          </Heading>
        </div>
        {data.map((item) => (
          <div
            key={item.id}
            tw="grid md:grid-cols-3 grid-cols-1 gap-8 md:py-6 py-4 items-center"
          >
            <div tw="md:py-6">
              {item.groupOneFeatures &&
                item.groupOneFeatures.map((feature) => (
                  <div
                    key={feature.id}
                    tw="max-w-xl py-4 px-8 bg-white border rounded-lg mb-6"
                  >
                    <div>
                      <Subtitle>
                        {feature.title}
                        <span tw="text-secondary">{feature.redText}</span>
                      </Subtitle>
                      <ul tw="list-disc ml-3 mt-2">
                        {feature.details?.map((desc) => (
                          <li key={desc} tw="text-sm mb-2 text-tertiary">
                            {desc}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
            </div>
            <div>
              <img src={item.image} alt="app-features" />
            </div>
            <div tw="md:py-6">
              {item.groupTwoFeatures &&
                item.groupTwoFeatures.map((feature) => (
                  <div
                    key={feature.id}
                    tw="max-w-xl py-4 px-8 bg-white border rounded-lg mb-6"
                  >
                    <div>
                      <Subtitle>
                        {feature.title}
                        <span tw="text-secondary">{feature.redText}</span>
                      </Subtitle>
                      <ul tw="list-disc ml-3 mt-2">
                        {feature.details?.map((desc) => (
                          <li key={desc} tw="text-sm mb-2 text-tertiary">
                            {desc}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </Container>
);
export default AppFeatures;
