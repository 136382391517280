import 'twin.macro';
import React from 'react';

import Layout from '../Layout';
import AdminSection from './AdminFeatures';
import Hero from './Hero';
import OverView from './OverView';
import AppFeatures from './AppFeatures';
import ProductComponents from './ProductComponents';
import config from '../../utils/config';
import Seo from '../Seo';

export default function PageTemplate({ pageContext }) {
  const product = config.productsData.find(
    (element) => element.slug === pageContext.slug,
  );
  if (!product) {
    return null;
  }
  return (
    <Layout>
      <Seo
        title={product?.title}
        url={`${config.siteUrl}/product/${product?.slug}`}
        image={product?.image}
      />
      <div>
        <Hero data={product} />
        <div tw="container px-4 mx-auto">
          <OverView data={product.overView} />
        </div>
        <ProductComponents data={product.productComponents} />
        <AdminSection data={product.dashboardFeatures} />
        <AppFeatures data={product.appFeatures} />
      </div>
    </Layout>
  );
}
