import 'twin.macro';
import React from 'react';

import { Heading, Paragraph } from '../atoms';
import Column from './Column';

const Hero = ({ data }) => (
  <div tw="md:py-10 py-4">
    <Column
      firstColumn={
        <img
          tw="rounded-2xl"
          src={data.image}
          height="450"
          alt="overview-img"
        />
      }
      secondColumn={
        <div tw="max-w-lg mx-auto py-10">
          <Heading isLarge>Overview</Heading>
          <div tw="mt-6">
            <Paragraph>{data.details}</Paragraph>
          </div>
        </div>
      }
    />
  </div>
);
export default Hero;
