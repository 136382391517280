import { styled } from 'twin.macro';
import React from 'react';

import { Subtitle, Heading } from '../atoms';

const Container = styled.div`
  background-image: url('/images/apps-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
  .bg-blur {
    background: rgba(0, 0, 0, 0.6);
  }
`;

const ProductComponents = ({ data }) => (
  <Container tw="mt-16">
    <div className="bg-blur">
      <div tw="container px-4 mx-auto py-20">
        <div tw="text-center mb-8">
          <Heading isLarge hasTextWhite>
            Product Components
          </Heading>
        </div>
        <div tw="grid md:grid-cols-2 grid-cols-1 gap-x-8 gap-y-4 max-w-screen-lg mx-auto">
          {data.map((item) => (
            <div key={item.id} tw="py-4 px-8 bg-white border rounded-lg mb-6 ">
              <div>
                <Subtitle>
                  {item.blackHeading}
                  <span tw="text-secondary">{item.redHeading}</span>
                  {item.title}
                </Subtitle>
                <p tw="text-sm text-tertiary mt-2">{item.details}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);
export default ProductComponents;
