import { styled } from 'twin.macro';
import React from 'react';

import { Heading } from '../atoms';
import Column from './Column';

const Container = styled.div`
  background-image: url('/images/product-banner.jpg');
  background-position: center;
  .bg-blur {
    background: rgba(0, 0, 0, 0.3);
  }
`;

const Hero = ({ data }) => (
  <Container tw="mb-20">
    <div className="bg-blur">
      <div tw="container px-4 mx-auto py-10">
        <Column
          firstColumn={
            <div tw="text-center py-6">
              <img
                tw="mx-auto"
                src={data.logo}
                alt={data.name}
                width="200"
                height="100"
              />
              <Heading hasTextWhite>{data.name}</Heading>
            </div>
          }
          secondColumn={
            <div tw="py-8">
              <img src={data.heroImage} alt={data.name} />
            </div>
          }
        />
      </div>
    </div>
  </Container>
);
export default Hero;
