import { styled } from 'twin.macro';
import React from 'react';

import Column from './Column';
import { Subtitle, Heading } from '../atoms';

const Container = styled.div`
  /* https://img.freepik.com/free-vector/flat-abstract-background_23-2149123691.jpg?t=st=1645627593~exp=1645628193~hmac=a4affd32250d7051e8afa8cec0f28806984ddc777220fee44d8e33c27f7e21c2&w=740 */
  background-image: url('/images/dashboard-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center bottom;
  background-size: cover;
  .bg-blur {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const AdminFeatures = ({ data }) => (
  <Container>
    <div className="bg-blur">
      <div tw="container px-4 mx-auto pt-16 pb-10">
        <div tw="text-center">
          <Heading isLarge>Admin Dashboard</Heading>
        </div>
        {data &&
          data.map((item, i) => (
            <div key={item.id} tw="py-6">
              <Column
                isReverse={(i + 1) % 2 !== 0}
                firstColumn={
                  <div>
                    <img src={item.image} alt="dashboard" />
                  </div>
                }
                secondColumn={
                  <div>
                    {item.features &&
                      item.features.map((feature) => (
                        <div
                          key={feature.id}
                          tw="max-w-xl py-4 px-8 bg-white border rounded-lg mb-6"
                        >
                          <div>
                            <Subtitle>{feature.title}</Subtitle>
                            <ul tw="list-disc ml-3 mt-2">
                              {feature.details?.map((desc) => (
                                <li
                                  key={desc}
                                  tw="text-sm mb-2 leading-normal text-tertiary"
                                >
                                  {desc}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                  </div>
                }
              />
            </div>
          ))}
      </div>
    </div>
  </Container>
);
export default AdminFeatures;
