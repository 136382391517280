import tw from 'twin.macro';
import React from 'react';
import { Heading } from '../atoms';

const Column = ({ title, isGap, firstColumn, secondColumn, isReverse }) => (
  <div>
    {title && (
      <div tw="mt-10 mb-6">
        <Heading isLarge>{title}</Heading>
      </div>
    )}
    <div
      css={[
        tw`md:flex items-center justify-between items-center md:gap-8 gap-6 my-4`,
        isReverse && tw`flex-row-reverse`,
        isGap && tw`md:gap-14 gap-6`,
      ]}
    >
      <div tw="md:w-6/12 w-full">{firstColumn}</div>
      <div tw="md:w-6/12 w-full ">{secondColumn}</div>
    </div>
  </div>
);
export default Column;
